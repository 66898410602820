<template>
  <component :is="layout">
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <h5>Update paymentcard</h5>
        <div class="card border-0 shadow-sm mb-4 fadeInUp">
          <div class="card-body">
            <div class="row credit-card-inputs">
              <div class="form-group col-12 required">
                <label for="cardNumber" class="control-label"
                  >Card Number</label
                >
                <div id="cardNumber" class="form-control stripe-field" />
              </div>
              <div class="form-group col-md-8 required">
                <label for="cardExpiry" class="control-label">Expiry</label>
                <div id="cardExpiry" class="form-control stripe-field" />
              </div>
              <div class="form-group col-md-4 required">
                <label for="cardCvc" class="control-label">CVC</label>
                <div id="cardCvc" class="form-control stripe-field" />
              </div>
            </div>
            <ButtonLoading
              :loading="loading"
              :title="'Save'"
              :class="'btn btn-primary'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SubscriptionUpdate",
  components: {
    Default,
    ButtonLoading,
  },
  data: function() {
    return {
      loading: false,
      token: null,
      intent: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      stripeOptions: {
        style: {
          base: {
            fontSize: "16px",
            color: "#506690",
            fontSmoothing: "antialiased",
            "::placeholder": {
              color: "#C6D3E6",
            },
          },
          invalid: {
            color: "#e5424d",
            ":focus": {
              color: "#506690",
            },
          },
        },
      },
    };
  },
  mounted: function() {
    this.$utils.includeScript(
      "js.stripe.com/v3/",
      function() {
        window.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);

        const elements = window.stripe.elements();

        this.cardNumber = elements.create("cardNumber", this.stripeOptions);
        this.cardNumber.mount("#cardNumber");

        this.cardExpiry = elements.create("cardExpiry", this.stripeOptions);
        this.cardExpiry.mount("#cardExpiry");

        this.cardCvc = elements.create("cardCvc", this.stripeOptions);
        this.cardCvc.mount("#cardCvc");
      }.bind(this)
    );
  },
  created() {
    jsonAPI
      .post("/organization/subscription/intent")
      .then((response) => {
        this.intent = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    submitForm: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var self = this;
      self.loading = true;
      window.stripe
        .handleCardSetup(this.intent, this.cardNumber)
        .then(function(result) {
          if (result.error) {
            console.error(result.error);
            window.Bus.$emit("flash-message", {
              text: "card error",
              type: "error",
            });
            self.loading = false;
          } else {
            self.token = result.setupIntent.payment_method;
            jsonAPI
              .post("/organization/subscription/update", { token: self.token })
              .then(() => {
                self.$router.push({ name: "organization.subscription" });
              })
              .catch(() => {
                window.Bus.$emit("flash-message", {
                  text: "Could not update card",
                  type: "error",
                });
                self.loading = false;
              });
          }
        });
    },
  },
};
</script>
