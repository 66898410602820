








import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Spinner",
})
export default class Spinner extends Vue {}
